import React, { Component } from "react"

import Navigation from "../components/Navigation/Navigation"
import Footer from "../components/Footer/Footer"
import FormButton from "../components/Form/FormButton/FormButton"

import downIcon from '../images/down-arrow.svg'

import styles from '../styles/split-page.module.css'
import FormSuccess from "../components/Form/FormSuccess/FormSuccess"

import { getEmptyRequiredInputs, getInputs, submitInputs } from "../utils/General"
import Header from "../components/Header/Header"

class ContactUs extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        success: false,
        inputs: [
            {
                label: 'Name',
                id: 'name',
            },
            {
                label: 'Email',
                id: 'email'
            },
            {
                label: 'Message',
                id: 'message',
                type: 'textarea',
                tStyle: {
                    maxHeight: '121px'
                }
            }
        ]
    }

    form = React.createRef()

    changed = (id,value) => {
        this.setState({
            [id]: value
        })
    }

    submit = () => {
        const [processedInputs,error] = getEmptyRequiredInputs(this.state)
        if(error){
            this.setState({processedInputs})
            return false;
        }else{
            submitInputs(processedInputs,'contact').then(res => {
                if(res){
                    this.setState({success: true})
                }else{
                    console.log('err')
                }
            })
            return true
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header title={'Contact Us | BDEI'}/>
                <div className={styles.container}>
                    <Navigation dark absolute/>
                    <div className={`${styles.text} ${styles.textContact}`}>
                        <div className={styles.text__backdrop}/>
                        <h1 className={styles.text__header}>
                            We would love to hear from you.
                        </h1>
                        <p className={styles.text__subtext}>
                            Kindly fill the form {' '}
                            <span className={styles.right}>on the right</span>
                            <span
                                className={styles.below}
                                onClick={() => this.form.current.scrollIntoView({behavior: 'smooth'})}
                            >
                                below
                                <img src={downIcon} style={{width: '8px',marginLeft: '3px'}} alt=""/>
                            </span>
                        </p>
                    </div>
                    {
                        !this.state.success ? (
                            <div className={styles.formContainer} ref={this.form} id="form">
                                <form action="">
                                    <h1 className={styles.form__header}>Contact Us</h1>
                                    {getInputs(this.state,this.changed)}
                                    <FormButton
                                        style={{width: '127px'}}
                                        submit={this.submit}
                                    >
                                        Submit
                                    </FormButton>
                                </form>
                            </div>
                        ) : <FormSuccess/>
                    }
                    <Footer absolute dark darktext/>
                </div>
            </React.Fragment>
        )
    }
}

export default ContactUs