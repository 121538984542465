import React from "react"
import tech4devLogo from '../../images/tech4dev-bdei.svg'
import './Footer.css'
import tech4devLogoDark from '../../images/tech4dev.svg'
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons"

function Footer({ absolute = false, dark = false, darktext = false }) {
    return (
        <div className={`footer ${absolute && 'footer--absolute'}`}>
            <a href="http://tech4dev.com/" target="_blank" className={`tech4dev-link ${darktext && 'tech4dev-link--dark'}`} rel="noopener noreferrer">
                <p>An initiative of</p>
                <img src={tech4devLogo} alt="tech4devlink" className="tech4dev-link__img"/>
                {darktext && <img src={tech4devLogoDark} alt="tech4devlink" className="tech4dev-link__img tech4dev-link__img--dark"/>}
            </a>
            <SocialMediaIcons dark={dark}/>
        </div>
    )
}

export default Footer